import * as R from "ramda";

export const sortUtil = items => {
  const sorter = R.sortBy(R.path(["node", "acf", "order"]));
  const posts = sorter(items);
  return posts;
};

export const stripHtmlTags = str =>
{
   if ((str===null) || (str===''))
       return false;
  else
   str = str.toString();
  return str.replace(/<[^>]*>/g, '');
}


export const createSlugFromAddress = node => {
  // add municipality to slug
  let slug = "";
  if(node.address && node.municipality && node.name && node.address.length < 100 && node.municipality.length < 50) {
      slug = `${node.address}-${node.municipality}-${node.name}`;
      slug = slug.replace(/\s/g, '-');
      slug = slug.replace(',', '');
      slug = slug.replace(/ä/g, 'a');
      slug = slug.replace(/ö/g, 'o');
      slug = slug.replace(/å/g, 'a');
      slug = slug.replace(/Å/g, 'a');
      slug = slug.replace(/ä/g, 'a');
      slug = slug.replace(/Ä/g, 'o');
      slug = slug.replace('/', '');
  } else {
    slug = node.spot_id
  }

  return slug;
}
