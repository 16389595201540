import React from "react";
import { connect } from "react-redux";
import { graphql, Link } from "gatsby";
import LayoutController from "../components/LayoutController";
import Content from "../components/content";
import MiniMap from "../components/minimap";
import CheckIcon from '../images/icons/icon-check.svg';
import SomeShare from '../components/someShare';
import Feedback from '../components/feedback';
import { stripHtmlTags } from '../utils'

import "./style.scss";

const Spot = ({data, materials, language, location}) => {
  const returnSpot = (objects) => {
    if(objects.kivo !== null) {
      return objects.kivo;
    } 
  }
  const spot = returnSpot(data);

  const randomNumberGen = () => {
    return Math.round(Math.random() * 10000);
  }

  const returnAvailableMaterials = (statemats, spotmats) => {
      if(spotmats) {
      const spotMatCodes = spotmats.map(material => (material.code))

      const list = statemats.map(statemat => {
  
        if(statemat.node.language === language && spotMatCodes.includes(statemat.node.code)) {
          return (<li key={`${randomNumberGen()}-key`}><CheckIcon />{statemat.node.name}</li>);
        }
      })
      return list;
    }
  }

  const returnOpeningHoursByLang = (lang, spot) => {
      if(lang === 'fi') {
        if(spot.opening_hours_fi) {
          return `Avoinna: ${stripHtmlTags(spot.opening_hours_fi)}`;
        } else if (spot.opening_hours_sv) {
          return `Avoinna: ${stripHtmlTags(spot.opening_hours_sv)}`;
        }
      } else if(lang === 'sv') {
          if(spot.opening_hours_sv) {
            return `Öppna: ${stripHtmlTags(spot.opening_hours_sv)}`;
          } else if (spot.opening_hours_fi) {
            return `Öppna: ${stripHtmlTags(spot.opening_hours_fi)}`;
          }
      } else if (lang === 'en') {
        if(spot.opening_hours_en) {
          return `Open: ${stripHtmlTags(spot.opening_hours_en)}`;
        } else if (spot.opening_hours_fi) {
          return `Open: ${stripHtmlTags(spot.opening_hours_fi)}`;
        }
    } 
  }

  const returnDescriptionByLang = (lang, spot) => {
    if(lang === 'fi') {
      if(spot.description_fi) {
        return `${stripHtmlTags(spot.description_fi)}`;
      } else if (spot.description_sv) {
        return `${stripHtmlTags(spot.description_sv)}`;
      }
    } else if(lang === 'sv') {
        if(spot.description_sv) {
          return `${stripHtmlTags(spot.description_sv)}`;
        } else if (spot.description_fi) {
          return `${stripHtmlTags(spot.description_fi)}`;
        }
    } else if (lang === 'en') {
      if(spot.description_en) {
        return `${stripHtmlTags(spot.description_en)}`;
      } else if (spot.description_fi) {
        return `${stripHtmlTags(spot.description_fi)}`;
      }
  } 
}
/* client as to remove this for now. maybe comes back later so don't delete
  const returnNonAvailableMaterials = (statemats, spotmats) => {
    if(spotmats) {

    
    const spotMatCodes = spotmats.map(material => (material.code)) 
    const list = statemats.map(statemat => {
      if(statemat.node.language === language && !spotMatCodes.includes(statemat.node.code)) {
        return (<li className="nonAvailable" key={`${statemat.node.code}-key`}>{statemat.node.name}</li>);
      }
    })
    return list;
    }
  }
*/
    return (
      <LayoutController currentPath={location.pathname} title={spot.name} description={spot.address} image={null}>
        <div className="singleSpotPage">
          <div className="singleContainer">
          <Link to="/" className="backLink">
            {language === 'fi' ? 'Takaisin karttanäkymään' : null}
            {language === 'sv' ? 'Tillbaka till kartvy' : null}
            {language === 'en' ? 'Back to map view' : null}
          
          </Link>
      
          <h1>{spot.name}</h1>
            <h2>{spot.operator}</h2>
            <p>{spot.address}, {spot.postal_code} {spot.municipality}</p>
    
           <p>
             {returnOpeningHoursByLang(language, spot)}
           </p>
            <p>
            {language === 'fi' ? 'Henkilökuntaa paikalla: ' : null}
            {language === 'sv' ? 'Personal på plats: ' : null}
            {language === 'en' ? 'Staff on site: ' : null}
              
              {spot.occupied  && language ==='fi' ?  " Kyllä": null}
              {spot.occupied  && language ==='sv' ?  " Ja": null}
              {spot.occupied  && language ==='en' ?  " Yes": null}
              {!spot.occupied  && language ==='fi' ?  " Ei": null}
              {!spot.occupied  && language ==='sv' ?  " Nej": null}
              {!spot.occupied  && language ==='en' ?  " No": null}
              </p>
             <Content content={returnDescriptionByLang(language, spot)} /> 
             <br />
            <div className="grid-x">
              <div className="cell medium-8">
                <h3>
                {language === 'fi' ? 'Täällä vastaanotetaan: ' : null}
                {language === 'sv' ? 'Återvinn här: ' : null}
                {language === 'en' ? 'Recycle here: ' : null}
                </h3>
                <ul>
                  {returnAvailableMaterials(materials, spot.materials)}
                  {/*returnNonAvailableMaterials(materials, spot.materials)*/}
                </ul>
              </div>
              <div className="cell medium-4">
                <SomeShare title={spot.name} />
              </div>
            </div>
            {spot.geometry ? <MiniMap coordinates={spot.geometry.coordinates} /> :null}
            <Feedback heading="h2" spot={`${spot.name}: ${spot.address} ${spot.postal_code} ${spot.municipality}`} />
        </div>

        </div>
      </LayoutController>
    );
  }


  export default connect(state => ({
    materials: state.app.materials,
    language: state.language.language
  }), null)(Spot);

export const pageQuery = graphql`
  query currentKivoResults($id: String!) {
      kivo (id: { eq: $id }){
       id
        spot_id
        address
        geometry {
          coordinates
        }
        name
        municipality
        postal_code
        operator
        opening_hours_fi
        opening_hours_en
        opening_hours_sv
        occupied
        materials {
          name
          code
        }
        description_en
        description_fi
        description_sv
      }
  }
`;
