import React from "react";

import "./style.scss";

const Content = ({ content }) => {
 
  return (
    <div className="mainContent">
      <div dangerouslySetInnerHTML={{ __html: content }}  />
    </div>
  );
};

export default Content;
