import React from 'react';
import L from 'leaflet';
import LocateControl from 'leaflet.locatecontrol'
import "./style.scss";
// Redux actions


class MiniMap extends React.Component {

  componentDidMount() {
    this.recycleIcon = L.icon({
      iconUrl: '/marker.png',
      iconAnchor: [14, 40]
    });
    
    this.map = L.map('map', {
      center: [this.props.coordinates[1], this.props.coordinates[0]],
      zoom: 15,
      maxZoom: 18,
      preferCanvas: true,
      zoomControl: false,
      layers: [
        L.tileLayer('https://{s}.tile.openstreetmap.de/{z}/{x}/{y}.png', {
          attribution: '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
        }),
      ]
    });
    L.marker([this.props.coordinates[1], this.props.coordinates[0]], {
      icon: this.recycleIcon,
    }).addTo(this.map);
 

    L.control.zoom({position: 'bottomright'}).addTo(this.map);
    L.control.locate({keepCurrentZoomLevel: true, position: 'bottomright',  strings: {
      title: "Paikanna minut"
  }}).addTo(this.map);

  }
 

  render() {
    return (
      <div className="frcMiniMap">
        <div id="map"></div>
      </div>
    )
    
    
  }
}


export default MiniMap;